import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FooterWrapper,
  FooterSocialWrapper,
  FooterSocialIcons,
  // P,
} from "../elements"
import { Button } from "../components"
import { useWindowSize } from "../utils/useWindowSize"
import { __ENV_DEV__ } from "../utils/__ENV_DEV__"
import AllFreeTools from "../utils/AllFreeTools"
import { NEW_DOMAIN_TOOLS, AUDIO_TOOLS_HUB_LINK } from "../utils/constants"

export const Footer = () => {
  const [, , desktop] = useWindowSize()[0]
  const size = useWindowSize()[1]

  const data = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: { eq: "facebook.svg" }) {
        publicURL
      }
      linkedin: file(relativePath: { eq: "linkedin.svg" }) {
        publicURL
      }
      instagram: file(relativePath: { eq: "instagram.svg" }) {
        publicURL
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      email: file(relativePath: { eq: "attherate.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <FooterWrapper
      className="testR1"
      style={{
        display: "flex",
        flexDirection: "column",
        // margin: "44px 44px",
        fontSize: "var(--fsSmall)",
        lineHeight: "32px",
        // padding: "36px",
        margin: size < 1228 ? "44px 44px 0 44px" : "44px 0px",
      }}
    >
      {/* topside */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          // height: "100%",
          // maxHeight: "400px",
          width: "100%",
          flexWrap: "wrap",
        }}
        className="testR1 text-2xl"
      >
        {TEMP_FOOTER.map((itemObj, idx) => {
          if (!itemObj.show) {
            return ""
          }
          return (
            <div key={idx}>
              {itemObj.url && (
                <a
                  href={`${itemObj.url}`}
                  target={"_self"}
                  style={{
                    textDecoration: "none",
                    color: "var(--white2)",
                    // fontWeight: "bolder",
                  }}
                >
                  <h5
                    style={{
                      fontWeight: "bolder",
                      marginBottom: "12px",
                      marginRight: "22px",
                      marginTop: "22px",
                      color: "var(--white2)",
                      // fontSize: "var(--fsMedium)",
                      opacity: 0.8,
                      textDecoration: "underline",
                    }}
                    className="text-3xl"
                  >
                    {itemObj.name}
                  </h5>
                </a>
              )}

              {!itemObj.url && (
                <h5
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "12px",
                    marginRight: "22px",
                    marginTop: "22px",
                    color: "var(--white2)",
                    // fontSize: "var(--fsMedium)",
                    opacity: 0.8,
                  }}
                  className="text-3xl"
                >
                  {itemObj.name}
                </h5>
              )}

              {itemObj.show &&
                itemObj.links.map((linkObj, idx2) => {
                  if (linkObj.hide || linkObj.hide_footer) {
                    return ""
                  }
                  return (
                    <div
                      key={idx + "-" + idx2}
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      {linkObj.newTab ? (
                        <a
                          href={`${linkObj.link}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: "var(--white2)",
                            // fontWeight: "bolder",
                          }}
                        >
                          <h6>{linkObj.name}</h6>
                        </a>
                      ) : (
                        // <Button
                        //   href={!linkObj.newTab ? linkObj.link : ""}
                        //   color="var(--white2)"
                        //   hoverColor="var(--base)"
                        //   style={{ marginBottom: "4px", fontWeight: "normal" }}
                        // >
                        // this a tag helps to know when user leaves a site in a browser, during check in tools progressing
                        <a
                          href={!linkObj.newTab ? linkObj.link : ""}
                          target="_self"
                          style={{
                            textDecoration: "none",
                            marginBottom: "4px",
                            fontWeight: "normal",
                          }}
                          className="hover:text-blue-500"
                        >
                          <h6>{linkObj.name}</h6>
                        </a>
                        // {/* </Button> */}
                      )}
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>

      {/* bottom side */}
      <div
        style={{
          color: "",
          // marginBottom: "2rem",
          marginTop: "2rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {/* social icons*/}

        <div style={{ marginBottom: "12px" }}>
          <span style={{ color: "" }}>
            &copy;&nbsp;{new Date().getFullYear()}&nbsp;Charm Shot AI
          </span>
          {/* <span>&nbsp;All rights reserved</span> */}
        </div>
        <div>
          <FooterSocialWrapper>
            <FooterSocialIcons>
              {/* email */}
              {/* <a
                href="mailto:CharmShotAI@gmail.com"
                target="_self"
                rel="noopener noreferrer"
              >
                <img src={data.email.publicURL} alt="email logo" />
              </a> */}

              {/* twitter */}
              <a
                href="https://twitter.com/sealcodes"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={data.twitter.publicURL}
                  alt="twitter logo"
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                />
              </a>

              {/* facebook */}
              {/* <a
                href="https://facebook.com/CharmShotAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={data.facebook.publicURL} alt="facebook logo" />
              </a> */}

              {/* linkedin */}
              {/* <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={data.linkedin.publicURL} alt="linkedin logo" />
              </a> */}

              {/* instagram  */}
              {/* <a
                href="https://www.instagram.com/CharmShotAI"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={data.instagram.publicURL}
                  alt="instagram logo"
                  style={{
                    width: "44px",
                    height: "44px",
                  }}
                />
              </a> */}
            </FooterSocialIcons>
          </FooterSocialWrapper>
        </div>
      </div>
    </FooterWrapper>
  )
}

const TEMP_FOOTER = [
  {
    show: true,
    name: "CharmShotAI",
    links: [
      {
        name: "CharmShotAI Editor",
        link: "https://editor.charmshotai.com/",
        newTab: true,
        hide: !true,
      },
      {
        name: "Blog",
        link: "/blog",
      },
      {
        name: "Reviews",
        link: "/",
        hide: true,
      },
      {
        name: "Pricing",
        link: "/pricing",
      },
      {
        name: "Testimonials",
        link: "https://senja.io/p/charmshotai/t/9eb298c4-9cf7-4024-9ee4-8a44fb032325",
        newTab: true,
      },
      {
        name: "Press Kit",
        link: "https://drive.google.com/drive/folders/1i9MgYLqd5hmUHlaWnX4BMjNaHMujtLXX",
        newTab: true,
        hide: !true,
      },
      {
        name: "Roadmap",
        link: "https://charmshotai.canny.io/",
        newTab: true,
      },

      {
        name: "Changelog",
        link: "https://charmshotai.canny.io/changelog",
        newTab: true,
      },
      {
        name: "Affiliate",
        link: "https://billing.charmshotai.com/affiliates",
        newTab: true,
      },
    ],
  },
  {
    show: true,
    name: "Support",
    links: [
      {
        name: "Manage/Forgot License",
        link: "https://app.lemonsqueezy.com/my-orders/",
        newTab: true,
        hide: !true,
      },
      {
        name: "Contact Us",
        link: "/contact-us",
      },

      {
        name: "Manage Subscriptions",
        link: "https://billing.charmshotai.com/billing",
        newTab: true,
        hide: true,
      },
      {
        name: "Help",
        link: "/tag/help",
        hide: !true,
      },
      {
        name: "Tutorial",
        link: "/tag/tutorial",
        hide: !true,
      },

      {
        name: "FAQs",
        link: "/pricing/#faq",
      },
      {
        name: "Feedback",
        link: "https://charmshotai.canny.io/feature-requests",
        newTab: true,
      },
    ],
  },

  {
    show: true,
    name: "Image Tools",
    url: `${NEW_DOMAIN_TOOLS}/tools/`,
    links: [
      { name: "ImageTools Hub", link: NEW_DOMAIN_TOOLS },
      ...AllFreeTools,
    ],
  },
  {
    show: true,
    name: "Audio Tools",
    url: `${AUDIO_TOOLS_HUB_LINK}/tools/`,
    links: [
      { name: "AudioTools Hub", link: AUDIO_TOOLS_HUB_LINK },
      {
        name: "Bulk Audio Transcriber",
        link: `${AUDIO_TOOLS_HUB_LINK}/tools/bulk-audio-transcriber/`,
      },
      {
        name: "Bulk Audio Background Noise Remover",
        link: `${AUDIO_TOOLS_HUB_LINK}/tools/bulk-audio-background-noise-remover/`,
      },
      {
        name: "Bulk Audio Silence Remover",
        link: `${AUDIO_TOOLS_HUB_LINK}/tools/bulk-audio-silence-remover/`,
      },
      {
        name: "Bulk Audio Converter",
        link: `${AUDIO_TOOLS_HUB_LINK}/tools/bulk-audio-converter/`,
      },
    ],
  },

  {
    show: !true,
    name: "Compare",
    links: [
      {
        name: "Compare 1",
        link: "/tag/compare",
      },
      {
        name: "Compare 2",
        link: "/tag/compare",
      },
      {
        name: "Compare 3",
        link: "/tag/compare",
      },
    ],
  },

  {
    show: true,
    name: "Legal",
    links: [
      {
        name: "Privacy Policy",
        link: "/privacy",
      },
      {
        name: "Terms of Use",
        link: "/terms",
      },
      {
        name: "Refund Policy",
        link: "/refund",
      },
    ],
  },
]
